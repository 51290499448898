import { Button, Table } from "reactstrap";
import PropTypes from "prop-types";

function UsersTable({ users }) {
  if (users.length === 0) {
    return <p className="font-bold text-white">No data available</p>;
  }

  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Username</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Edited On</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {users &&
          users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>{user.email}</td>
              <td>{user.last_change}</td>
              <td className="text-center">
                <a href={`/history/edits/${user.username}/`}>
                  <Button>History</Button>
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    })
  ),
};

export default UsersTable;
