/* eslint-disable camelcase */

import BaseForm from "../common/BaseForm";
import cloneDeep from "../../utils/parseUtils";
import { insertNewFavorFactor, minutesToHourFormat, hourToMinutesFormat } from "../../utils/hourlyFavorFactorUtils";
import { Row, Col, Input, Form, Button } from "reactstrap";
import { toast } from "react-toastify";
import { updateGlobalStreamConfig } from "../../services/globalStreamConfigService";

class GlobalStreamConfigForm extends BaseForm {
  state = {
    streamConfig: null,
    backupStreamConfig: null,
    inputStartTime: "12:30",
    inputEndTime: "13:30",
    inputFavorFactor: 1,
    inputGlobalTargetRate: 0,
    confirmSave: false,
    globalTargetRateModified: false,
  };

  componentDidMount() {
    const streamConfig = cloneDeep(this.props.streamConfig);

    streamConfig.hourly_favor_factor.forEach((hourlyFavorFactor) => {
      hourlyFavorFactor.updated = false;
      const [start, end] = hourlyFavorFactor.period;
      delete hourlyFavorFactor.period;
      hourlyFavorFactor.start_minute = hourToMinutesFormat(start);
      hourlyFavorFactor.end_minute = hourToMinutesFormat(end);
    });

    const backupStreamConfig = cloneDeep(streamConfig);
    this.setState({
      streamConfig,
      backupStreamConfig,
      inputGlobalTargetRate: streamConfig.global_target_rate,
    });
  }

  apply = () => {
    const { streamConfig } = this.state;
    const inputStart = hourToMinutesFormat([...this.state.inputStartTime.split(":").map(Number)]);
    const inputEnd = hourToMinutesFormat([...this.state.inputEndTime.split(":").map(Number)]);
    const inputFavorFactor = parseFloat(this.state.inputFavorFactor);

    if (inputStart >= inputEnd || Number.isNaN(inputFavorFactor) || inputFavorFactor <= 0) {
      toast.error("Invalid input values");
      return;
    }

    const hourlyFavorFactor = insertNewFavorFactor(
      streamConfig.hourly_favor_factor,
      inputStart,
      inputEnd,
      inputFavorFactor
    );
    streamConfig.hourly_favor_factor = hourlyFavorFactor;
    this.setState({ streamConfig });
  };

  applyGlobalTargetRate = () => {
    const { streamConfig, inputGlobalTargetRate } = this.state;
    const globalTargetRate = parseFloat(inputGlobalTargetRate);

    if (Number.isNaN(globalTargetRate) || globalTargetRate <= 0 || globalTargetRate > 20) {
      toast.error("Invalid global target rate");
      return;
    }

    streamConfig.global_target_rate = globalTargetRate;
    this.setState({ streamConfig, globalTargetRateModified: true });
  };

  reset = () => {
    const streamConfig = cloneDeep(this.state.backupStreamConfig);
    this.setState({
      streamConfig,
      inputGlobalTargetRate: streamConfig.global_target_rate,
      globalTargetRateModified: false,
    });
  };

  save = async () => {
    const backupStreamConfig = cloneDeep(this.state.streamConfig);
    const streamConfigToSave = cloneDeep(this.state.streamConfig);
    streamConfigToSave.hourly_favor_factor.forEach((hourlyFavorFactor) => {
      delete hourlyFavorFactor.updated;
      const period = [
        minutesToHourFormat(hourlyFavorFactor.start_minute),
        minutesToHourFormat(hourlyFavorFactor.end_minute),
      ];
      hourlyFavorFactor.period = period;
      delete hourlyFavorFactor.start_minute;
      delete hourlyFavorFactor.end_minute;
    });
    await updateGlobalStreamConfig(streamConfigToSave);
    this.setState({ backupStreamConfig, globalTargetRateModified: false });
    this.toggleConfirmSave();
    toast.success(`Saved stream config for ${streamConfigToSave.day}`);
  };

  toggleConfirmSave = () => {
    const confirmSave = !this.state.confirmSave;
    this.setState({ confirmSave });
  };

  onInputChange = (field, value) => {
    if (field === "start") {
      const inputStartTime = value;
      this.setState({ inputStartTime });
    }
    if (field === "end") {
      const inputEndTime = value;
      this.setState({ inputEndTime });
    }
    if (field === "ff") {
      const inputFavorFactor = parseFloat(value);
      this.setState({ inputFavorFactor });
    }
    if (field === "globalTargetRate") {
      const inputGlobalTargetRate = parseFloat(value);
      this.setState({ inputGlobalTargetRate });
    }
  };

  render() {
    const {
      streamConfig,
      inputStartTime,
      inputEndTime,
      inputFavorFactor,
      inputGlobalTargetRate,
      confirmSave,
      globalTargetRateModified,
    } = this.state;

    return (
      <Form className="mt-3">
        <Row>
          <Col className="pr-md-1" md="3">
            <h5>Current hourly favor factors</h5>
            {streamConfig &&
              streamConfig.hourly_favor_factor &&
              streamConfig.hourly_favor_factor.map((ff_range) => (
                <div
                  key={`${ff_range.start_minute.toString}h${ff_range.end_minute.toString}`}
                  style={{ color: ff_range.updated ? "green" : "" }}
                >
                  <span>
                    {`${Math.trunc(ff_range.start_minute / 60)
                      .toString()
                      .padStart(2, "0")}h${Math.trunc(ff_range.start_minute % 60)
                      .toString()
                      .padStart(2, "0")} to ${Math.trunc(ff_range.end_minute / 60)
                      .toString()
                      .padStart(2, "0")}h${Math.trunc(ff_range.end_minute % 60)
                      .toString()
                      .padStart(2, "0")} : `}
                  </span>
                  <span>{ff_range.favor_factor}</span>
                </div>
              ))}
          </Col>
          <Col className="pr-md-1" md="5">
            <h5>Update global target rate</h5>
            <div className="input-group mb-3">
              <span className="mr-2 mt-2">Global target rate:</span>
              <Input
                className="form-control border border-dark rounded"
                style={{
                  color: globalTargetRateModified ? "green" : "",
                }}
                type="number"
                step="0.1"
                value={inputGlobalTargetRate}
                onChange={(e) => this.onInputChange("globalTargetRate", e.target.value)}
              />
            </div>
            <div>
              <Button className="float-right m-2" onClick={this.reset}>
                <i className="fas fa-times mr-2" /> Reset
              </Button>
              <Button className="float-right m-2" onClick={this.applyGlobalTargetRate}>
                <i className="fas fa-check mr-2" /> Apply
              </Button>
            </div>
            <br />
            <br />
            <h5>Update favor factors</h5>
            <div className="input-group">
              <span className="mr-2 mt-2">Between</span>
              <Input
                className="form-control border border-dark rounded"
                type="time"
                value={inputStartTime}
                onChange={(e) => this.onInputChange("start", e.target.value)}
              />
              <span className="mx-2 mt-2">and</span>
              <Input
                className="form-control border border-dark rounded"
                type="time"
                value={inputEndTime}
                onChange={(e) => this.onInputChange("end", e.target.value)}
              />
            </div>
            <div className="input-group">
              <span className="mr-2 mt-2">Favor factor :</span>
              <Input
                className="form-control border border-dark rounded"
                type="number"
                step="0.1"
                value={inputFavorFactor}
                onChange={(e) => this.onInputChange("ff", e.target.value)}
              />
            </div>
            <div>
              <Button className="float-right m-2" onClick={this.reset}>
                <i className="fas fa-times mr-2" /> Reset
              </Button>
              <Button className="float-right m-2" onClick={this.apply}>
                <i className="fas fa-check mr-2" /> Apply
              </Button>
            </div>
            {!confirmSave && (
              <div>
                <Button className="float-right btn-success m-2" onClick={this.toggleConfirmSave}>
                  <i className="fas fa-save mr-2" /> Save All
                </Button>
              </div>
            )}
            {confirmSave && (
              <div className="save-confirmation">
                <Button className="float-right m-2" onClick={this.toggleConfirmSave}>
                  <i className="fas fa-times mr-2" /> Cancel
                </Button>
                <Button className="float-right btn-success m-2" onClick={this.save}>
                  <i className="fas fa-save mr-2" /> Save
                </Button>
                <span className="float-right">Are you sure?</span>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

export default GlobalStreamConfigForm;
