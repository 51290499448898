import { Button, Table } from "reactstrap";
import PropTypes from "prop-types";

function StoresTable({ stores }) {
  if (stores.length === 0) {
    return <p className="font-bold text-white">No data available</p>;
  }

  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Location</th>
          <th>Editor</th>
          <th>Edited On</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {stores &&
          stores.map((history) => (
            <tr key={history.id}>
              <td>{history.location}</td>
              <td>{history.editor}</td>
              <td>{history.last_change}</td>
              <td className="text-center">
                <a href={`/history/stores/${history.location}/`}>
                  <Button>History</Button>
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

StoresTable.propTypes = {
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default StoresTable;
