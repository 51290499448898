import React, { Component } from "react";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import { getGlobalStreamConfigs } from "../services/globalStreamConfigService";
import GlobalStreamConfigForm from "../components/Forms/GlobalStreamConfigForm";
import "../components/GlobalStreamConfig.scss";

class GlobalStreamConfigView extends Component {
  state = {
    streamConfigs: null,
    activeStreamConfigIndex: 0,
  };

  async componentDidMount() {
    await this.fetchGlobalStreamConfigs();
  }

  async fetchGlobalStreamConfigs() {
    const { data: streamConfigs } = await getGlobalStreamConfigs();
    this.setState({ streamConfigs });
  }

  toggle(streamConfigIndex) {
    const { activeStreamConfigIndex } = this.state;
    if (activeStreamConfigIndex !== streamConfigIndex) {
      this.setState({
        activeStreamConfigIndex: streamConfigIndex,
      });
    }
  }

  render() {
    const { streamConfigs, activeStreamConfigIndex } = this.state;

    return (
      <div className="content">
        <Card>
          <CardHeader>
            <h5 className="card-category">Stream configs</h5>
          </CardHeader>
          {!streamConfigs && (
            <CardBody>
              <Spinner color="primary" />
            </CardBody>
          )}
          {streamConfigs && (
            <CardBody>
              <Nav tabs>
                {streamConfigs.map((streamConfig, streamConfigIndex) => (
                  <NavItem role="button" key={streamConfigIndex}>
                    <NavLink
                      className={activeStreamConfigIndex === streamConfigIndex ? "active" : ""}
                      onClick={() => {
                        this.toggle(streamConfigIndex);
                      }}
                    >
                      <p>{streamConfig.day}</p>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeStreamConfigIndex}>
                {streamConfigs.map((streamConfig, streamConfigIndex) => (
                  <TabPane key={streamConfig.day} tabId={streamConfigIndex}>
                    {streamConfig && <GlobalStreamConfigForm streamConfig={streamConfig} />}
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          )}
        </Card>
      </div>
    );
  }
}

export default GlobalStreamConfigView;
