import * as Sentry from "@sentry/react";
import { Button, Table } from "reactstrap";
import formatDate from "../utils";

function UserChangesTable({ userHistories, currentPage, pageSize, onSeeChanges }) {
  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Location</th>
          <th>Edited on (mm/dd/yyyy)</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {userHistories &&
          userHistories.map((userHistory, index) => {
            const globalIndex = (currentPage - 1) * pageSize + index;
            return (
              <tr key={userHistory.id}>
                <td>{userHistory.location}</td>
                <td>
                  <p className="text-info font-bold">{formatDate(userHistory.created_at)}</p>
                </td>
                <td className="text-center">
                  <Button onClick={() => onSeeChanges(globalIndex)}>See changes</Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

export default Sentry.withProfiler(UserChangesTable);
