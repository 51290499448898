import * as Sentry from "@sentry/react";
import { Button, Table } from "reactstrap";
import formatDate from "../utils";

function StoreChangesTable({ storeHistories, storeLocation, currentPage, pageSize, onSeeChanges }) {
  const formatUser = (username, email) => {
    if (username && email && username !== email) {
      return `${username}/${email}`;
    }

    if (username) {
      return `${username}`;
    }

    if (email) {
      return `${email}`;
    }

    return "";
  };

  return (
    <Table className="tablesorter" responsive>
      <thead className="text-primary">
        <tr>
          <th>Location</th>
          <th>Edited on (mm/dd/yyyy)</th>
          <th>Editor</th>
          <th className="text-center" />
        </tr>
      </thead>
      <tbody>
        {storeHistories &&
          storeHistories.map((storeHistory, index) => {
            const globalIndex = (currentPage - 1) * pageSize + index;
            return (
              <tr key={`${storeHistory.id}`}>
                <td>{storeLocation}</td>
                <td>
                  <p className="text-info font-bold">{formatDate(storeHistory.created_at)}</p>
                </td>
                <td>
                  <p className="text-info">{formatUser(storeHistory.user__username, storeHistory.user__email)}</p>
                </td>
                <td className="text-center">
                  <Button onClick={() => onSeeChanges(globalIndex)}>See changes</Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

export default Sentry.withProfiler(StoreChangesTable);
