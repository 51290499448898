const historyBaseEndPoint = "/history";

export function getHistoryEndpoint(historyType, identifier) {
  return `${historyBaseEndPoint}/${historyType}/${identifier}/`;
}

// Store
export function getStoresHistoriesPaginatedEndpoint({ page = 1, pageSize = 10, query = "" }) {
  return `${historyBaseEndPoint}/stores/?page=${page}&page_size=${pageSize}&filter=${query}`;
}

export function getStoreHistoryEndpoint(location) {
  return `${historyBaseEndPoint}/stores/${location}/`;
}

// User
export function getUsersHistoriesPaginatedEndpoint({ page = 1, pageSize = 10, query = "" }) {
  return `${historyBaseEndPoint}/users/?page=${page}&page_size=${pageSize}&filter=${query}`;
}

export function getUserHistoryEndpoint(username) {
  return `${historyBaseEndPoint}/users/${username}/`;
}
